import React from 'react'
import './Solution.css'
import Solution from '../images/bg5.jpg'
const Solutions = () => {
  return (
    <section id="solutions">
      <h2 className='solutionData'>Solutions</h2> 
<div className="solution-container">
<div className="image-column-Solution">
        <img src={Solution} alt="Solution" />
      </div>
      <div className="text-column-solution">
        <p className="DetailsSolution">Our Approach
</p>
          <p className='introSolution'>At Amvocus, we combine advanced research methodologies with innovative technologies to gather comprehensive data and deliver insightful analyses. Our tailored approach ensures that each client receives customized solutions that meet their unique needs and objectives.
 </p>
          
        <button className="dropBtnSolution" >
         <a href='#contact'> Reach out for Free Demo</a>
        </button>
      </div>
    </div>

  </section>
  )
}

export default Solutions;