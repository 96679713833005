import React, { useState } from 'react';
import './Header.css'; // Import CSS file for 
import logoIMG from '../images/LOGO.png'

const Header = ({ handleInquiryClick }) => {
    const [showMenu, setShowMenu] = useState(false);
   
    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };
    const handleClick = (e) => {
        const inquiry = e.target.innerText; // Get text content of the clicked <a> tag
        handleInquiryClick(inquiry);
      };
    return (
        <header className="header">
            {/* Logo on the left */}
            <div className="logo">
                <img src={logoIMG} alt='logo'/>
            </div>

            {/* Menu button for mobile */}
            <div className="menu-button" onClick={toggleMenu}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>

            {/* Dropdown navbar */}
            <div className={`navbar ${showMenu ? 'show' : ''}`}>
                <div className="dropdown">
                    <button className="dropbtn"><a href="#home">Home</a></button>
                </div>
                <div className="dropdown">
                    <button className="dropbtn"><a href="#solution">About</a></button>
                </div>
                <div className="dropdown"   onMouseEnter={() => {document.getElementById('dataDropdown').classList.add('show')}} onMouseLeave={() => {document.getElementById('dataDropdown').classList.remove('show')}}>
                    <button className="dropbtn"><a href="#brand">Services</a></button>
                        <div className="dropdown-content" id="dataDropdown">
                            <a href="#contact" onClick={handleClick}>Find Your Audience</a>
                            <a href="#contact" onClick={handleClick}>Build a survey</a>
                            <a href="#contact" onClick={handleClick}>B2B Research</a>
                            <a href="#contact" onClick={handleClick}>Consumer Insights</a>
                            <a href="#contact" onClick={handleClick}>Health Care Professionals</a>
                            <a href="#contact" onClick={handleClick}>Advertising Effectiveness</a>
                            <a href="#contact" onClick={handleClick}>Publish & Share</a>

                        </div>
                </div>
                <div className="dropdown">
                    <button className="dropbtn"><a href="#dataPortal">CRM</a></button>
                    
                </div>
                <div className="dropdown">
                    <button className="dropbtn"><a href="#panel">Panel</a></button>
                    
                </div>
                <div className="dropdown">
                    <button className="dropbtn"><a href="#quality">Quality</a></button>
                </div>
                <div className="dropdown" onMouseEnter={() => {document.getElementById('dataDropdown').classList.add('show')}} onMouseLeave={() => {document.getElementById('dataDropdown').classList.remove('show')}}>
                    <button className="dropbtn"><a href="#solutions">Solutions</a></button>
                    <div className="dropdown-content" id="dataDropdown">
                            <a href="#contact" onClick={handleClick}>Data & Insights Solutions</a>
                            <a href="#contact" onClick={handleClick}>Advertising Solutions</a>
                        </div>
                </div>
                <div className="dropdown">
                    <button className="dropButton"><a href="#contact">Contact Us</a></button>
                </div>
            </div>
        </header>
    );
};

export default Header;
