import React from 'react';
import './Footer.css'; // Import your CSS file for styling
import LogoImg from '../images/LOGO.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faXTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons'; // Adjusted import
const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__logo">
        <img src={LogoImg} alt="Company Logo" />
      </div>
      <div className="footer__columns">
        <div className="footer__column">
          <h3>About Us</h3>
          <ul>
            <li> <a href="mailto:info@amvocus.com">info@amvocus.com</a></li>
            <li>206, Global heights, sector 33, Gurgaon, Haryana</li>
            <li className='icons'> 
              <a href="https://www.facebook.com/yourcompany" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook}/>
              </a>
              <a href="https://www.twitter.com/yourcompany" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faXTwitter} />
              </a>
              <a href="https://www.linkedin.com/company/yourcompany" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin}/>
              </a>
              <a href="https://www.linkedin.com/company/yourcompany" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram}/>
              </a>
              {/* Add more social media icons and links as needed */}
            </li>
          </ul>
        </div>
        <div className="footer__column">
          <h3>Services</h3>
          <ul>
            <li>Market Research</li>
            <li>Data Analysis</li>
            <li>Consulting Services</li>
          </ul>
        </div>
        <div className="footer__column">
          <h3>Why to Choose Us</h3>
          <ul>
          <li>Client-Centric</li>
            <li>Expertise</li>
            <li>Innovation</li>
           
          </ul>
        </div>
        <div className="footer__column">
          <h3>Our Commitment</h3>
          <ul>
          <li>Deliver Excellence</li>
            <li>Strategic Insights</li>
            <li>Market Trends</li>
           
          </ul>
        </div>
        
      </div>
    </footer>
  );
};

export default Footer;
