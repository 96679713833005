import React, { useState,useEffect } from 'react';
import './Contact.css'
import { useForm} from '@formspree/react';

const Contact = ({  inquiryValue  }) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [inquiry, setInquiry] = useState({inquiryValue});
  useEffect(() => {
    setInquiry(inquiryValue);
  }, [inquiryValue]);


  const [state, handleSubmit] = useForm("xdoqbvrd");
  const handleFormSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission
    await handleSubmit(event); // Call the handleSubmit function provided by useForm hook
    if (state.succeeded) {
      alert("Form submitted successfully");
      // Reset form fields
      setName("");
      setEmail("");
      setInquiry("");
      setMessage("");
    }
  };

  return (
    <section id="contact">
       <h3 className='HeadingContact'>Contact Us</h3> 
      <div className="contact-container">
        <div className="form-container">
          <form onSubmit={handleFormSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input type="text" id="name" name="name" value={name} placeholder='Enter Name' onChange={(e) => setName(e.target.value)}  autoComplete='off' required/>
            </div>
            <div className="form-group">
              <label htmlFor="incquiry">Inquiry</label>
              <input type="inquiry" id="inquiry"  name="inquiry" value={inquiry} placeholder='Enter Reason for Inquiry' onChange={(e) => setInquiry(e.target.value)} autoComplete='off' required/>
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="email" id="email" name="email" value={email} placeholder='Enter Email' onChange={(e) => setEmail(e.target.value)}  autoComplete='off' required/>
            </div>

            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea id="message" name="message" rows="5" value={message} placeholder='Draft Your Query' onChange={(e) => setMessage(e.target.value)} autoComplete='off' required></textarea>
            </div>
            <button className='contactBtn' type="submit" disabled={state.submitting}>Submit</button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
