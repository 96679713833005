import React from 'react'
import './Product.css';
import productImg from '../images/bg2.jpg'

const Product = () => {
  return (
    <section id="solution">
      <h2>About Us</h2> 
      <div className="products-container">
        <div className="text-column">
          <p className="Details">We help companies develop greater insights</p>
            <p className='intro'>Welcome to Amvocus, your trusted partner in market research and insights. 
            With a dedicated team of industry experts and cutting-edge technology, we deliver actionable 
            data-driven solutions to help businesses thrive in today's dynamic market landscape.
</p>


            
          <button className="dropBtn">
            <a href='#contact'>Become a Partner</a>
          </button>
        </div>
        <div className="image-column">
          <img src={productImg} alt="Product" />
        </div>
      </div>

    </section>
  )
}

export default Product;
