
import React from 'react'
import './BrandConnect.css';
const Choose = () => {
  return (
    <section id="quality">
    <h2 className="connect">Why Choose Us?</h2>
    <div className="products-contain">
      <div className="column">
        <h3>EXPERTISE</h3>
        <p> Our team consists of seasoned professionals with expertise in various industries, ensuring high-quality research and analysis.</p>
      </div>
      <div className="column">
        <h3>INNOVATION</h3>
        <p>We leverage the latest technologies and methodologies to deliver innovative solutions that drive business success.
</p>
      </div>
      <div className="column">
        <h3>CLIENT-CENTRIC</h3>
        <p>We prioritize client satisfaction and work collaboratively to exceed expectations and deliver measurable results.
</p>
      </div>
    </div>
  </section>
  )
}

export default Choose
