import React, { useEffect, useState } from 'react';
import './Panel.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile, faDiagramProject, faBrain, faEarthAmericas } from '@fortawesome/free-solid-svg-icons'; 


const Counter = ({ finalNumber }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (count < finalNumber) {
        setCount(prevCount => prevCount + 1);
      } else {
        clearInterval(interval);
      }
    }, 10); // Adjust the interval as needed (in milliseconds)
    
    return () => clearInterval(interval); // Cleanup on component unmount
  }, [count, finalNumber]);

  return <h1 className='panelCount'>{count}</h1>;
};

const Panel = () => {
  return (
    <section id="panel">
      <h2 className='PanelHeading'>Panel</h2> 
      <div className="panel-container">
        <div className="block">
          <Counter finalNumber={70} />
          <div className="icon">
            <FontAwesomeIcon icon={faSmile} className="fa-icon"/>
          </div>
          <p className='panelPara'>Happy Clients</p>
        </div>
        <div className="block">
          <Counter finalNumber={100} />
          <div className="icon">
            <FontAwesomeIcon icon={faDiagramProject} className="fa-icon"/>
          </div>
          <p className='panelPara'>Projects</p>
        </div>
        <div className="block">
          <Counter finalNumber={2} />
          <div className="icon">
            <FontAwesomeIcon icon={faBrain} className="fa-icon"/>
          </div>
          <p className='panelPara'>Years of Experience</p>
        </div>
        <div className="block">
          <Counter finalNumber={10} />
          <div className="icon">
            <FontAwesomeIcon icon={faEarthAmericas} className="fa-icon"/>
          </div>
          <p className='panelPara'>Countries</p>
        </div>
   
      </div>
      <div className='panel-book'>
      <button className="BtnPanel" >
          <a href="https://shorturl.at/buyCE" rel="noreferrer" target='_blank'>Panel Book</a>
        </button>
      </div>
    
    </section>
  );
};

export default Panel;
