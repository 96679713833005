import React from 'react'
import './BrandConnect.css';
const BrandConnect = () => {
  return (
    <section id="brand">
    <h2 className="connect">Our Services</h2>
    <div className="products-contain">
      <div className="column">
        <h3>MARKET RESEARCH</h3>
        <p>We conduct in-depth market research studies to uncover industry trends, customer preferences, and competitive landscapes.</p>
      </div>
      <div className="column">
        <h3>DATA ANALYSIS</h3>
        <p>Our team of data analysts utilizes advanced analytics tools to extract meaningful insights from complex datasets, helping clients identify opportunities and mitigate risks.</p>
      </div>
      <div className="column">
        <h3>CONSULTING SERVICES</h3>
        <p>We provide strategic consulting services to support clients in developing effective business strategies based on market trends and consumer behavior.
</p>
      </div>
    </div>
  </section>
  )
}

export default BrandConnect
