// App.js
import React, { useState } from 'react';
import Header from './components/Header';
import Body from './components/Body'
import Product from './components/Product'
import BrandConnect from './components/BrandConnect';
import PortalData from './components/PortalData';
import Solution from './components/Solutions';
import Contact from './components/Contact';
import Choose from './components/Choose';
import Footer from './components/Footer';
import Panel from './components/Panel';

function App() {
  const [inquiryValue, setInquiryValue] = useState('');

  const handleInquiryClick = (value) => {
    setInquiryValue(value);
  };

  return (
    <div className="App">
      <Header handleInquiryClick={handleInquiryClick} />
      <Body />
      <Product />
      <BrandConnect />
      <PortalData />
      <Panel/>
      <Choose />
      <Solution />
      <Contact inquiryValue={inquiryValue} />
      <Footer />
    </div>
  );
}

export default App;
