// Body.js
import React from 'react';
import './Body.css';
import logoIMG from '../images/LOGO.png'


const Body = () => {
 

  return (
    <section id="home">
    <div className="body">
      <div className="content">
         <img src={logoIMG} alt='logo' />
        <p className='desc'>Unlock the exclusive network of consumers and business professionals within Amvocus proprietary</p>
      </div>
    </div>
    </section>
  );
};

export default Body;
