import React from 'react'
import './PortalData.css'
import CRM from '../images/bg6.jpg'
const PortalData = () => {
  return (
    <section id="dataPortal">
      <h2 className='Data'>CRM Data</h2> 
    <div className="data-container">
    <div className="image-column-data">
        <img src={CRM} alt="Product" />
      </div>
      <div className="text-column-data">
        <p className="DetailsData">Our Mission</p>
          <p className='introData'>Integrate your CRM data, providing insights into your current customer base, with our reliable first-party data on consumer motivations. Our mission is to empower businesses with accurate and actionable market intelligence, enabling them to make informed decisions and drive sustainable growth.
</p>
          
        <button className="dropBtnData" >
          <a href="#contact">Become a Client To Join Us</a>
        </button>
      </div>
    </div>

  </section>
  )
}

export default PortalData